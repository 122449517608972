import { getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import Client, { getHeaderAndText } from "../../components/client/Client";
import { getNavigation } from "../../components/clients/Clients";
import i18nContext from "../../components/i18n/i18n.context";
import Layout from "../../components/layout/Layout";
import Page from "../../components/page/Page";
import SEO from "../../components/seo/Seo";
import i18n from "../../i18n/wonderbox.i18n.js";
import { useQuery } from "../../static-queries/wonderbox";
import { getHTMLContent } from "../../utils";

function WonderboxClientPage({ location }) {
  const strings = i18n["fr"];
  const { header, text, clientCover } = getHeaderAndText({
    strings,
    staticQuery: useQuery(),
  });
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          description={getHTMLContent(strings.blocks[0].description)}
          image={getImage(clientCover).src}
          keywords={["Jawg", "JawgMaps", "Témoignage", "Wonderbox"]}
          location={location}
          metaDescription="Wonderbox nous parle de son expérience client avec Jawg Map."
          socialTitle="Témoignage Wonderbox"
          title="Témoignage | Wonderbox"
        />
        <Page blue>
          <Client
            author={strings["author"]}
            header={header}
            nav={getNavigation("wonderbox", "fr")}
            quote={strings["quote"]}
            text={text}
          />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

WonderboxClientPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default WonderboxClientPage;
